<template>
  <v-layout
    align-start
    justify-start
    column
    fill-height
    wrap
    class="pibot-mapviewer"
  >
    <pibot-loading-overlay :loading="loading" />
    <pibot-mapbox />
    <pibot-map-fit-bounds-control :offset="[0, 6.2]" />
    <pibot-map-style-control :offset="[0, 8.7]" />
    <pibot-map-ruler :offset="[0, 11.2]" />

    <pibot-mapbox-asset-control v-if="appFeatures.assetControl"
                                position="top-right"
                                :offset="controlOffsets['assetControl']" />

    <pibot-mapbox-layer-control v-if="appFeatures.layerControl"
                                position="top-right"
                                :offset="controlOffsets['layerControl']" />

    <pibot-mapbox-property-control v-if="appFeatures.propControl"
                                   position="top-right"
                                   :offset="controlOffsets['propControl']" />

    <pibot-mapbox-inspection-control v-if="appFeatures.inspectionControl"
                                     position="top-right"
                                     :offset="controlOffsets['inspectionControl']" />

    <pibot-mapbox-eventline-control v-if="appFeatures.eventLineControl"
                                    position="top-right"
                                    :offset="controlOffsets['eventLineControl']" />

    <pibot-mapbox-filter-control v-if="appFeatures.filterControl"
                                 position="top-right"
                                 :offset="controlOffsets['filterControl']"
                                 :categories="['inspection']"
                                 :filters="filterProps" />

    <pibot-mapbox-risk-toggle-basic v-if="appFeatures.riskToggleBasic"
                                    position="top-right"
                                    :offset="controlOffsets['riskToggleBasic']" />

    <pibot-mapbox-risk-toggle-menu v-if="appFeatures.riskToggleMenu"
                                    position="top-right"
                                    :offset="controlOffsets['riskToggleMenu']" />

    <pibot-mapbox-risk-toggle-pof-cof v-if="appFeatures.riskTogglePofCof"
                                      position="top-right"
                                      :offset="controlOffsets['riskTogglePofCof']" />

    <pibot-mapbox-legends position="bottom-right"
                          :offset="legendsOffset" />

    <pibot-mapbox-mouse-coords position="bottom-left"
                               :offset="mouseCoordsOffset" />

    <pibot-mapbox-incident-toggle v-if="appFeatures.incidentToggle"
                               position="top-right"
                               theme="light"
                               :offset="controlOffsets['incidentToggle']" />

    <pibot-mapbox-bottom-chart v-if="appFeatures.bottomChart"
                               position="top-right"
                               theme="light"
                               :offset="controlOffsets['bottomChart']" />

  </v-layout>
</template>

<script>
import Mapbox from '@/components/mapbox/viewer/Mapbox'
import LoadingOverlay from '@/components/overlays/LoadingOverlay'
import FitBoundsControl from '@/components/mapbox/controls/FitBoundsControl/FitBoundsControl'
import StyleControl from '@/components/mapbox/controls/StyleControl/StyleControl'
import Ruler from '@/components/mapbox/controls/Ruler/Ruler'
import config from '@/apps/mapbox/config'
import { mapState } from 'vuex'

export default {
  name: 'pibot-mapviewer',
  components: {
    'pibot-loading-overlay': LoadingOverlay,
    'pibot-mapbox': Mapbox,
    'pibot-map-fit-bounds-control': FitBoundsControl,
    'pibot-map-style-control': StyleControl,
    'pibot-map-ruler': Ruler,
    'pibot-mapbox-asset-control': () => import('@/apps/mapbox/features/AssetControl/components/AssetControl'),
    'pibot-mapbox-layer-control': () => import('@/apps/mapbox/features/LayerControl/components/LayerControl'),
    'pibot-mapbox-property-control': () => import('@/apps/mapbox/features/PropertyControl/components/PropertyControl'),
    'pibot-mapbox-inspection-control': () => import('@/apps/mapbox/features/InspectionControl/components/InspectionControl'),
    'pibot-mapbox-eventline-control': () => import('@/apps/mapbox/features/EventLineControl/components/EventLineControl'),
    'pibot-mapbox-filter-control': () => import('@/apps/mapbox/features/FilterControl/components/FilterControl'),
    'pibot-mapbox-risk-toggle-basic': () => import('@/apps/mapbox/features/RiskControl/components/RiskToggleBasic'),
    'pibot-mapbox-risk-toggle-menu': () => import('@/apps/mapbox/features/RiskControl/components/RiskToggleMenu'),
    'pibot-mapbox-risk-toggle-pof-cof': () => import('@/apps/mapbox/features/RiskControl/components/RiskTogglePofCof'),
    'pibot-mapbox-legends': () => import('@/components/mapbox/legends/Legend'),
    'pibot-mapbox-mouse-coords': () => import('@/components/mapbox/controls/MouseCoordsControl/MouseCoordsControl'),
    'pibot-mapbox-incident-toggle': () => import('@/apps/mapbox/features/IncidentControl/IncidentControl'),
    'pibot-mapbox-bottom-chart': () => import('@/components/mapbox/charts/BottomChart')
  },
  data () {
    return {
      appFeatures: config.features,
      filterProps: [
        {
          id: 'bathy',
          categories: ['Bathy Survey'],
          filterBy: 'Event Type',
          label: 'Filter Bathy Survey data'
        }
        // {
        //   id: 'ili-code',
        //   categories: ['ILI'],
        //   filterBy: 'Feature code',
        //   label: 'Filter ILI data [Code]'
        // }
        // {
        //   id: 'ili-id',
        //   categories: ['ILI'],
        //   filterBy: 'Feature identification',
        //   label: 'Filter ILI data [Identification]'
        // }
      ]
    }
  },
  computed: {
    ...mapState({
      mapLoaded: state => state.mapbox.mapLoaded,
      dataPending: state => state.mapbox.dataPending,
      mapIdle: state => state.mapbox.mapIdle
    }),
    loading () {
      return !this.mapLoaded || this.dataPending || !this.mapIdle
    },
    controlOffsets () {
      const offsets = {}
      let offsetIterator = 0

      Object.keys(this.appFeatures).forEach(f => {
        if (this.appFeatures[f] === true) {
          offsets[f] = [0, (2.5 * offsetIterator)]
          offsetIterator++
        } else offsets[f] = [0, 0]
      })

      return offsets
    },
    legendsOffset () {
      // if (!this.appFeatures.bottomChart) return [0, -0.2]
      // return [-0.3333, -2.75]
      return [0, -0.2]
    },
    mouseCoordsOffset () {
      return [0.666666666666667, -4.5]
    }
  }
}
</script>
