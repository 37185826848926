export default {
  props: {
    offset: {
      type: Array,
      default: () => [0, 0]
    }
  },
  computed: {
    offsets () {
      const offsets = []
      for (let i = 0; i < 2; i++) {
        if (this.offset[i] !== undefined) offsets.push(`${this.offset[i]}rem`)
      }
      return offsets
    },
    offsetStyle () {
      const offsets = this.offsets.join(',')
      return { transform: `translate(${offsets})` }
    }
  }
}
