<template>
  <div class="mapbox-gl-control ruler" :style="[offsetStyle, posStyle]">
    <v-tooltip :left="attachLeft" :right="!attachLeft">
      <template #activator="{ on }">
        <v-btn
          class="pa-1 ma-2"
          min-width="0"
          height="auto"
          :color="isMeasuring ? 'primary darken-1' : 'white'"
          :dark="isMeasuring"
          small
          v-on="on"
          @click="toggle"
        >
          <v-icon style="transform: rotate(90deg);">height</v-icon>
        </v-btn>
      </template>
      <span>Measure distance</span>
    </v-tooltip>
  </div>
</template>

<script>
/**
 * Based on the mapbox-gl-controls plugin
 * @see https://github.com/bravecow/mapbox-gl-controls
 */
import positionStyleMixin from '@/utils/mixins/styling/positionStyle.mixin.js'
import offsetStyleMixin from '@/utils/mixins/styling/offsetStyle.mixin.js'
import mapboxgl from 'mapbox-gl'
import { point, nearestPoint, distance } from '@turf/turf'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'pibot-map-ruler',
  mixins: [positionStyleMixin, offsetStyleMixin],
  data () {
    return {
      LAYER_LINE: 'controls-layer-line',
      LAYER_SYMBOL: 'controls-layer-symbol',
      SOURCE_LINE: 'controls-source-line',
      SOURCE_SYMBOL: 'controls-source-symbol',
      markers: [],
      coordinates: [],
      labels: []
    }
  },
  computed: {
    ...mapState({
      map: state => state.mapbox.map,
      mapLoaded: state => state.mapbox.mapLoaded,
      isMeasuring: state => state.mapbox.isMeasuring
    })
  },
  methods: {
    ...mapMutations({
      setIsMeasuring: 'mapbox/setIsMeasuring'
    }),
    geoLineString (coordinates = []) {
      return {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates
        }
      }
    },
    geoPoint (coordinates = [], labels = []) {
      return {
        type: 'FeatureCollection',
        features: coordinates.map((c, i) => ({
          type: 'Feature',
          properties: {
            text: labels[i]
          },
          geometry: {
            type: 'Point',
            coordinates: c
          }
        }))
      }
    },
    coordinatesToLabels (coordinates) {
      let sum = 0
      return coordinates.map((c, i) => {
        if (i === 0) return 0
        sum += distance(coordinates[i - 1], coordinates[i])
        if (sum < 1) {
          return `${(sum * 1000).toFixed()} m`
        }
        return `${sum.toFixed(2)} km`
      })
    },
    toggle () {
      this.setIsMeasuring(!this.isMeasuring)

      if (this.isMeasuring) this.measuringOn()
      else this.measuringOff()
    },
    draw () {
      this.map.addSource(this.SOURCE_LINE, {
        type: 'geojson',
        data: this.geoLineString(this.coordinates)
      })

      this.map.addSource(this.SOURCE_SYMBOL, {
        type: 'geojson',
        data: this.geoPoint(this.coordinates, this.labels)
      })

      this.map.addLayer({
        id: this.LAYER_LINE,
        type: 'line',
        source: this.SOURCE_LINE,
        paint: {
          'line-color': '#263238',
          'line-width': 2
        }
      })

      this.map.addLayer({
        id: this.LAYER_SYMBOL,
        interactive: true,
        type: 'symbol',
        source: this.SOURCE_SYMBOL,
        layout: {
          'text-field': '{text}',
          'text-font': ['Roboto Medium'],
          'text-anchor': 'top',
          'text-size': 12,
          'text-offset': [0, 0.8]
        },
        paint: {
          'text-color': '#263238',
          'text-halo-color': '#fff',
          'text-halo-width': 1
        }
      })
      // this.layersInitiated = true
    },
    measuringOn () {
      this.markers = []
      this.coordinates = []
      this.labels = []
      this.map.getCanvas().style.cursor = 'crosshair'
      this.draw()
      this.map.on('click', this.mapClickListener)
      this.map.on('style.load', this.mapLoadListener)
    },
    measuringOff () {
      this.map.getCanvas().style.cursor = ''
      // remove layers, sources and event listeners
      if (this.map.getLayer(this.LAYER_LINE)) this.map.removeLayer(this.LAYER_LINE)
      if (this.map.getLayer(this.LAYER_SYMBOL)) this.map.removeLayer(this.LAYER_SYMBOL)
      if (this.map.getSource(this.SOURCE_LINE)) this.map.removeSource(this.SOURCE_LINE)
      if (this.map.getSource(this.SOURCE_SYMBOL)) this.map.removeSource(this.SOURCE_SYMBOL)
      this.markers.forEach(m => m.remove())
      this.map.off('click', this.mapClickListener)
      this.map.off('style.load', this.mapLoadListener)
    },
    mapClickListener (event) {
      // // the user can be this far away from the item for it to register
      // const margin = 10
      // // e.point contains the pixel location, so this creates a bounding box with a margin
      // const bbox = [
      //   [event.point.x - margin, event.point.y - margin],
      //   [event.point.x + margin, event.point.y + margin]
      // ]
      // const features = this.map.queryRenderedFeatures(bbox, {layers: [this.LAYER_SYMBOL]})

      // if (features.length) this.removeMarker(event) // A point was clicked, remove this point
      // else this.addMarker(event) // No point was clicked, add a new one
      this.addMarker(event)
    },
    mapLoadListener () {
      this.isMeasuring = false
      this.measuringOff()
    },
    addMarker (event) {
      const markerNode = document.createElement('div')
      markerNode.style.width = '12px'
      markerNode.style.height = '12px'
      markerNode.style.borderRadius = '50%'
      markerNode.style.background = '#fff'
      markerNode.style.boxSizing = 'border-box'
      markerNode.style.border = '2px solid #263238'
      const marker = new mapboxgl.Marker({
        element: markerNode,
        draggable: true
      })
        .setLngLat(event.lngLat)
        .addTo(this.map)

      this.coordinates.push([event.lngLat.lng, event.lngLat.lat])
      this.labels = this.coordinatesToLabels(this.coordinates)
      this.map.getSource(this.SOURCE_LINE).setData(this.geoLineString(this.coordinates))
      this.map.getSource(this.SOURCE_SYMBOL).setData(this.geoPoint(this.coordinates, this.labels))
      this.markers.push(marker)

      marker.on('drag', () => {
        const index = this.markers.indexOf(marker)
        const lngLat = marker.getLngLat()
        this.coordinates[index] = [lngLat.lng, lngLat.lat]
        this.labels = this.coordinatesToLabels(this.coordinates)
        this.map.getSource(this.SOURCE_LINE).setData(this.geoLineString(this.coordinates))
        this.map.getSource(this.SOURCE_SYMBOL).setData(this.geoPoint(this.coordinates, this.labels))
      })
    },
    removeMarker (event) {
      /**
       * queryRenderedFeatures() does not return the exact feature coordinates as it exists in the layer.
       * Due to this we have to use turf to find the real exact coordinates
       */
      const targetPoint = point([event.lngLat.lng, event.lngLat.lat])
      const points = this.map.getSource(this.SOURCE_SYMBOL)._data
      const nearest = nearestPoint(targetPoint, points)

      // Remove point from coordinates
      this.coordinates = this.coordinates.filter((c) => JSON.stringify(c) !== JSON.stringify(nearest.geometry.coordinates))
      this.labels = this.coordinatesToLabels(this.coordinates)
      this.map.getSource(this.SOURCE_LINE).setData(this.geoLineString(this.coordinates))
      this.map.getSource(this.SOURCE_SYMBOL).setData(this.geoPoint(this.coordinates, this.labels))

      // Remove corresponding marker
      for (const m of this.markers) {
        const mCoords = JSON.stringify(Object.values(m.getLngLat()))
        const nCoords = JSON.stringify(nearest.geometry.coordinates)
        if (mCoords === nCoords) {
          m.remove()
          break
        }
      }
    }
  }
}
</script>
