<template>
  <div class="mapbox-gl-control style-control" :style="[offsetStyle, posStyle]">
    <v-menu
      v-model="menu"
      :close-on-content-click="true"
      :nudge-width="200"
      :left="attachLeft"
      offset-x
      z-index="10"
    >
      <template #activator="{ on: menu }">
        <v-tooltip :left="attachLeft" :right="!attachLeft">
          <template #activator="{ on: tooltip }">
            <v-btn
              class="pa-1 ma-2"
              height="auto"
              min-width="0"
              color="white"
              light
              small
              v-on="{ ...tooltip, ...menu }"
            >
              <v-icon>terrain</v-icon>
            </v-btn>
          </template>
          <span>Map style</span>
        </v-tooltip>
      </template>

      <v-card outlined flat>
        <v-card-text>
          <slot>
            <p>Map style</p>
          </slot>
          <v-radio-group :value="mapStyle" @change="mapStyleChanged" hide-details>
            <v-radio
              v-for="option in mapStyleOptions"
              :label="option.title"
              :value="option.uri"
              :key="option.uri"
              color="primary darken-1"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import positionStyleMixin from '@/utils/mixins/styling/positionStyle.mixin.js'
import offsetStyleMixin from '@/utils/mixins/styling/offsetStyle.mixin.js'
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'pibot-map-style-control',
  mixins: [positionStyleMixin, offsetStyleMixin],
  data () {
    return {
      menu: false
    }
  },
  computed: {
    ...mapState({
      mapStyleOptions: state => state.mapbox.mapStyles,
      mapStyle: state => state.mapbox.mapStyle
    })
  },
  methods: {
    ...mapMutations({
      setMapStyle: 'mapbox/setMapStyle'
    }),
    mapStyleChanged (newStyle) {
      this.setMapStyle(newStyle)
    }
  }
}
</script>

<style>
</style>
