export default {
  props: {
    position: {
      type: String,
      default: 'top-left'
    }
  },
  data () {
    return {
      attachLeft: false
    }
  },
  computed: {
    posStyle () {
      const style = {
        position: 'absolute',
        top: 'unset',
        right: 'unset',
        bottom: 'unset',
        left: 'unset'
      }

      if (this.position === 'top-left') {
        style.top = 0
        style.left = 0
      } else if (this.position === 'top-right') {
        style.top = 0
        style.right = 0
        this.attachLeft = true
      } else if (this.position === 'bottom-left') {
        style.bottom = 0
        style.left = 0
      } else if (this.position === 'bottom-right') {
        style.bottom = 0
        style.right = 0
        this.attachLeft = true
      }
      return style
    }
  }
}
