<template>
  <div class="mapbox-gl-control fit-bounds-control" :style="[offsetStyle, posStyle]">
    <v-tooltip :left="attachLeft" :right="!attachLeft">
      <template #activator="{ on }">
        <v-btn
          class="pa-1 ma-2"
          min-width="0"
          height="auto"
          color="white"
          small
          v-on="on"
          @click="moveActiveLayersInView"
        >
          <v-icon>zoom_out_map</v-icon>
        </v-btn>
      </template>
      <span>Fit layers in view</span>
    </v-tooltip>
  </div>
</template>

<script>
import positionStyleMixin from '@/utils/mixins/styling/positionStyle.mixin.js'
import offsetStyleMixin from '@/utils/mixins/styling/offsetStyle.mixin.js'
import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'

export default {
  name: 'pibot-map-fit-bounds-control',
  mixins: [positionStyleMixin, offsetStyleMixin, mapboxBasicsMixin]
}
</script>

<style>
</style>
